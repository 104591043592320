/* eslint-disable quotes */
import React, { useContext, useReducer, useMemo, useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { FormField2 as Field } from 'components';
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  HeaderCell,
  Cell,
} from "@table-library/react-table-library/table";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import { Virtualized } from "@table-library/react-table-library/virtualized";
import { useTree, CellTree, TreeExpandClickTypes } from "@table-library/react-table-library/tree";
import { Storage as StorageIcon, Search as SearchIcon } from '@material-ui/icons';
import { IconButton, ButtonGroup, Checkbox, FormControlLabel, Button, Typography } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// import ValueImporter from './ValueImporter';
import ActionMenu from './ActionMenu';
import TreeEditableCell from './TreeEditableCell';

const ROW_HEIGHT = 36;
const ROW_HEIGHT_WITH_COEFS = 40;
const ROW_HEIGHT_EXPANDED = 64;

import {
  EstimateContext,
  TYPES,
  DETAIL_LEVEL,
  DETAIL_LEVEL_VISIBILITY,
} from './EstimateObjectForm';

const EstimateTreeTable = () => {
  const { t } = useTranslation();
  const [newRowIndex, setNewRowIndex] = useState(null);
  const [showRowOrderArrows, setShowRowOrderArrows] = useState(false);
  const {
    record,
    // loading,
    treeExpandedRowKeys,
    setTreeExpandedRowKeys,
    itemActions,
    // treeDetailLevel,
    // setTreeDetailLevel,
    estimateCurrentEditable,
    setActiveItem,
    setShowRatesModal,
    itemAdded,
    setItemAdded,
    setShowObjectSummaryModal,
  } = useContext(EstimateContext);

  const data = { nodes: record.items };

  const onTreeChange = (action, state) => {
    console.log({ action, state });

    if (newRowIndex) {
      const scrollTop = ROW_HEIGHT * (newRowIndex);
      const tableElement = document.querySelector('.tree-table>div>div');
      const windowHeight = (window.innerHeight - 400) * 0.8;

      tableElement.scrollTop + windowHeight < scrollTop ? tableElement.scrollTo({
        'behavior': 'smooth',
        'left': 0,
        'top': scrollTop - ROW_HEIGHT * 6
      }
      ) : null;
      setNewRowIndex(null);
      setItemAdded(null);
    }
  }

  const tree = useTree(data, {
    onChange: onTreeChange,
  }, {
    // treeYLevel: 1,
    indentation: 5,
    clickType: TreeExpandClickTypes.ButtonClick,
    treeIcon: {
      size: '10px',
      // margin: '4px',
      iconRight: <>&#x25BA;</>,
      iconDown: <>&#x25BC;</>,
    },
  });

  const theme = useTheme({
    Table: `
        --data-table-library_grid-template-columns:  12% 40% 7% 7% 7% 7% 10% 7% 3%;
      `,
    BaseCell: `
      &:nth-of-type(1) {
        left: 0px;
      }

      &:nth-of-type(2) {
        left: 110px;
      }
      &:last-of-type{
        right: 0px;
      }
    `,
  });

  useEffect(() => {
    if (!treeExpandedRowKeys || !treeExpandedRowKeys?.length) return;

    tree.fns.onAddByIds(treeExpandedRowKeys);
    setTreeExpandedRowKeys(null);
  }, [treeExpandedRowKeys]);

  // const expandByDetailLevel = (level) => {
  //   if (!level) return;

  //   const visibleTypes = DETAIL_LEVEL_VISIBILITY[level];

  //   const flat = (array) => {
  //     let result = [];
  //     array.forEach(function (a) {
  //       result.push({ type: a.type, _id: a._id });
  //       if (Array.isArray(a.children)) {
  //         result = result.concat(flat(a.children));
  //       }
  //     });
  //     return result;
  //   }

  //   const allIds = flat(record.items);

  //   const idsToExpand = allIds
  //     .filter(a => visibleTypes.includes(a.type))
  //     .map(a => a._id);

  //   setTreeExpandedRowKeys(idsToExpand);
  // };

  // const columns = useMemo(() => {
  //   return [
  //     // { key: 'code', label: t('Kodas'), renderCell: renderCodeCell, tree: true },
  //     { key: 'code', label: t('Kodas'), renderCell: (item) => item.code, tree: true },
  //     { key: 'title', label: t('Pavadinimas'), renderCell: (item) => item.title },
  //     { key: 'unit', label: t('Mato vnt.'), renderCell: (item) => item.unit },
  //     { key: 'norm', label: t('Norma'), renderCell: (item) => item.norm },
  //     { key: 'quantity', label: t('Kiekis'), renderCell: (item) => item.quantity },
  //     { key: 'price', label: t('Kaina'), renderCell: (item) => item.price },
  //     { key: 'amount', label: t('Suma'), renderCell: (item) => item.amount },
  //   ];
  // });

  const renderType = (text, record) => {
    let value = typeLangMap[text] || text;

    if (text === TYPES.RATE && record.rateType) {
      value = `${t('Įk.')} ${rateTypeLangMap[record.rateType].toLowerCase()}`;
    } else if (text === TYPES.RESOURCE && record.resourceType) {
      value = resourceTypeLangMap[record.resourceType];
    }

    return (
      <span title={value}>{value}</span>
    )
  }

  const typeLangMap = {
    'estimate': t('Sąmata'),
    'part': t('Sąm. dalis'),
    'chapter': t('Skyrius'),
    'subchapter': t('Poskyris'),
    'group': t('El. grupė'),
    'element': t('Elementas'),
    'rate': t('Įkainis'),
    'resource': t('Resursas'),
  }

  const resourceTypeLangMap = {
    'work': t('Darbas'),
    'mechanism': t('Mechanizmas'),
    'material': t('Medžiaga'),
    'equipment': t('Įrenginys'),
    'other': t('Kita'),
  }

  const rateTypeLangMap = {
    'work': t('Darbas'),
    'mechanism': t('Mechanizmas'),
    'material': t('Medžiaga'),
    'equipment': t('Įrenginys'),
  }

  const handleCellChange = ({ name, value, item }) => {
    itemActions.cellChanged(name, item, value);
  }

  const showRatesModal = (id, parent, code, quantityCalc, title) => {
    setActiveItem({ _id: id, parent, code, quantityCalc, title });
    setShowRatesModal(true);
  }

  const renderRateCoefficients = (coefficients = {}) => {
    // Coefficients: K1, K2, K3, K11, K21, K31, K41, K51
    // Output format: k1=1.2,1.3 k2=0.4 ...
    const output = [];

    // Object.keys(coefficients).forEach(key => {
    //   const values = coefficients[key].filter(v => Number(v) !== 1);
    //   if (!values.length) return;

    //   output.push(`${key}=${values.join(',')}`);
    // });

    Object.keys(coefficients).forEach(key => {
      const values = [];
      const coeffs = coefficients[key];
      if (!coeffs.length) return;

      for (const coeff of coeffs) {
        if (coeff.type === 'coef' && coeff.value === 1) continue;

        if (coeff.type === 'coef') {
          values.push(coeff.value);
        } else if (coeff.type === 'amount') {
          values.push(`${coeff.value}EUR`);
        } else if (coeff.type === 'percent') {
          values.push(`${coeff.value}%`);
        } else {
          console.error(`Coefficient type ${coeff.type} not supported`);
        }
      }

      if (values.length) output.push(`${key}=${values.join(',')}`);
    });

    if (!output.length) return null;

    return (
      <div className="title-coefficients">
        {output.join(' ')}
      </div>
    )
  }

  const handleReorder = async (id, order) => {
    try {
      await itemActions.reorderItem(id, order);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
        <div
          style={{ margin: '16px 0px 8px 0px', display: 'flex' }}
        >
          <Field
            label={t('Detalizavimas')}
            name="detail-level"
            // value={treeDetailLevel}
            value={null}
            placeholder={t('Pasirinkite...')}
            type="select"
            options={[
              { value: DETAIL_LEVEL.ESTIMATE, label: t('Sąmata') },
              { value: DETAIL_LEVEL.PART, label: t('Sąmatos dalis') },
              { value: DETAIL_LEVEL.CHAPTER, label: t('Skyrius') },
              { value: DETAIL_LEVEL.SUBCHAPTER, label: t('Poskyris') },
              { value: DETAIL_LEVEL.RATE, label: t('Įkainis') },
              { value: DETAIL_LEVEL.RESOURCE, label: t('Įkainis ir resursai') },
            ]}
            style={{ width: 140 }}
            onChange={({ value }) => {
              if (value === DETAIL_LEVEL.ESTIMATE) {
                tree.fns.onRemoveAll();
                return;
              }

              let ids = [];

              const loopNodes = (nodes) => {
                nodes.forEach(node => {
                  if (node.type == value) {
                    ids = ids.concat(node.path.filter(v => v !== node.id));
                  }
                  if (node?.nodes?.length) loopNodes(node.nodes);
                });
              }

              loopNodes(data.nodes);
              console.log({ value, ids });

              tree.fns.onRemoveAll();
              tree.fns.onAddByIds(ids);
            }}
          />

          <FormControlLabel
            control={<Checkbox checked={showRowOrderArrows}
              onChange={() => setShowRowOrderArrows(!showRowOrderArrows)}
              name="showRowOrderArrowsCheckbox" />}
            label="Keisti eilučių išdėstymą?"
            style={{ paddingLeft: 20 }}
          />
        </div>

        <div style={{ margin: '8px 0px', display: 'flex', justifyContent: 'flex-end' }}>
          <div style={{ width: 130 }}>
            <Typography style={{ fontWeight: 600 }}>{t('Suma, EUR be PVM')}</Typography>
            <Typography>{record.totals.amount}</Typography>
          </div>
          <div style={{ width: 130, paddingLeft: 10 }}>
            <Typography style={{ fontWeight: 600 }}>{t('Suma, EUR su PVM')}</Typography>
            <Typography>{record.totals.grandTotal}</Typography>
          </div>
          <div style={{ paddingLeft: 10 }}>
            <Button
              variant="outlined"
              color="primary"
              style={{ fontSize: '10px', marginTop: '5px' }}
              onClick={() => setShowObjectSummaryModal(true)}>
              {t('Kainos sudėtis')}
            </Button>
          </div>
        </div>
      </div>

      <div style={{ height: `${window.innerHeight - 300}px` }}>
        <Table
          data={data}
          layout={{
            isDiv: true,
            fixedHeader: true,
            custom: true,
          }}
          tree={tree}
          className="tree-table"
          theme={theme}
        >
          {(tableList) => (
            <Virtualized
              tableList={tableList}
              rowHeight={ROW_HEIGHT}
              // rowHeight={(item) => {
              //   return Object.keys(item?.coefficients).find(k => !!item.coefficients?.[k]?.length) ? ROW_HEIGHT_WITH_COEFS : ROW_HEIGHT;
              //   // const currentBeingEdited = estimateCurrentEditable?.id === item.id && estimateCurrentEditable?.name === 'title';
              //   // return currentBeingEdited ? ROW_HEIGHT_EXPANDED : ROW_HEIGHT;
              // }}
              header={() => (
                <HeaderRow>
                  {/* <HeaderCell hide>index</HeaderCell> */}
                  <HeaderCell stiff pinLeft resize>{t('Kodas')}</HeaderCell>
                  <HeaderCell pinLeft resize>{t('Pavadinimas')}</HeaderCell>
                  <HeaderCell pinLeft resize>{t('Mato vnt.')}</HeaderCell>
                  <HeaderCell pinLeft resize>{t('Norma')}</HeaderCell>
                  <HeaderCell pinLeft resize>{t('Kiekis')}</HeaderCell>
                  <HeaderCell pinLeft resize>{t('Kaina')}</HeaderCell>
                  <HeaderCell pinLeft resize>{t('Suma')}</HeaderCell>
                  <HeaderCell pinLeft resize>{t('Tipas')}</HeaderCell>
                  <HeaderCell pinLeft resize />
                </HeaderRow>
              )}
              body={(item, index) => (
                item._id == itemAdded ? setNewRowIndex(index) : null,
                <Row
                  id={item._id}
                  item={item}
                  style={item.type === "resource" && (item.norm == 0 || item.price == 0 || item.amount == 0) ? { color: "red" } : null}
                  className={`tree-row-type-${item.type} row-key-${item._id}`}
                >
                  {/* <Cell hide>{item.index}</Cell> */}
                  {!!item.nodes?.length ? (
                    <CellTree
                      item={item}
                      pinLeft
                      style={{ paddingLeft: `${item.level * 5}px` }}
                    >
                      <TreeEditableCell
                        name="code"
                        value={item.code || '-'}
                        onChange={handleCellChange}
                        item={item}
                        inputProps={{ type: 'text' }}
                      />
                    </CellTree>
                  ) : (
                    <Cell
                      pinLeft
                      style={{ paddingLeft: `${item.level * 5 + 5}px` }}
                    >
                      <TreeEditableCell
                        name="code"
                        value={item.code || '-'}
                        onChange={handleCellChange}
                        item={item}
                        inputProps={{ type: 'text' }}
                      />
                    </Cell>
                  )}
                  <Cell pinLeft>
                    <TreeEditableCell
                      name="title"
                      value={item.title}
                      onChange={handleCellChange}
                      item={item}
                      type="textarea"
                      inputProps={{ rows: 5 }}
                      style={item.type === 'rate' ? { paddingRight: '20px' } : {}}
                    />

                    {item.type === 'rate' ? (
                      <div className="cell-addons">
                        <div style={{ position: 'absolute', right: '3px', top: '7px' }}>
                          <a
                            href="#"
                            title={t('Įkelti įkainį')}
                            onClick={() => showRatesModal(item._id, item.parent, item.code, item.quantity, item.title)}>
                            <StorageIcon />
                          </a>
                        </div>

                        {renderRateCoefficients(item.coefficients)}
                      </div>
                    ) : (
                      <div className="cell-addons">
                        {/* <div style={{ position: 'absolute', right: '3px', top: '7px' }}>

                          <ValueImporter
                            title={t('Pavadinimo paieška')}
                            onValueSelect={(value) => handleCellChange({ name: 'title', value: value.value, item })}
                          />
                        </div> */}

                        {renderRateCoefficients(item.coefficients)}
                      </div>
                    )}
                  </Cell>
                  <Cell style={{ textAlign: 'center' }}>
                    <TreeEditableCell
                      name="unit"
                      value={item.unit}
                      onChange={handleCellChange}
                      item={item}
                      inputProps={{ type: 'text' }}
                    />
                  </Cell>
                  <Cell style={{ textAlign: 'right' }}>
                    <TreeEditableCell
                      name="norm"
                      value={item.norm}
                      onChange={handleCellChange}
                      item={item}
                    // inputProps={{ type: 'number', min: 0 }}
                    />
                  </Cell>
                  <Cell style={{ textAlign: 'right', position: 'relative' }}>
                    <TreeEditableCell
                      name="quantity"
                      value={item.quantity}
                      onChange={handleCellChange}
                      item={item}
                      // inputProps={{ type: 'number', min: 0 }}
                      style={{ paddingLeft: '20px' }}
                    />

                    <div className="cell-addons">
                      {/* <div style={{ position: 'absolute', left: '3px', top: '7px' }}>
                        <ValueImporter
                          title={t('Kiekio paieška')}
                          onValueSelect={(value) => handleCellChange({ name: 'quantity', value: value.value, item })}
                          selectOnlyNumbers
                        />
                      </div> */}
                    </div>
                  </Cell>
                  <Cell style={{ textAlign: 'right' }}>
                    <TreeEditableCell
                      name="price"
                      value={item.price}
                      onChange={handleCellChange}
                      item={item}
                    // inputProps={{ type: 'number', min: 0 }}
                    />
                  </Cell>
                  <Cell style={{ textAlign: 'right' }}>
                    <TreeEditableCell
                      name="amount"
                      value={item.amount}
                      onChange={handleCellChange}
                      item={item}
                    // inputProps={{ type: 'number', min: 0 }}
                    /></Cell>
                  <Cell style={{ textAlign: 'center' }}>{renderType(item.type, item)}</Cell>
                  <Cell style={{ textAlign: 'center', padding: 0 }} pinRight>
                    {showRowOrderArrows ? (
                      <ButtonGroup orientation="vertical">
                        <IconButton
                          size="small"
                          edge="start"
                          disabled={item.parentNode.nodes[0]._id == item._id}
                          color="primary"
                          style={{ height: 15 }}
                          onClick={() => handleReorder(item._id, item.order - 1)}
                        >
                          <KeyboardArrowUpIcon />
                        </IconButton>
                        <IconButton
                          size="small"
                          edge="start"
                          disabled={item.parentNode.nodes[item.parentNode.nodes.length - 1]._id == item._id}
                          color="primary"
                          style={{ height: 15 }}
                          onClick={() => handleReorder(item._id, item.order + 1)}
                        >
                          <KeyboardArrowDownIcon />
                        </IconButton>
                      </ButtonGroup>
                    ) : (
                      <ActionMenu
                        item={item}
                      />
                    )
                    }
                  </Cell>
                </Row>
              )}
            />
          )}
        </Table>
      </div >
    </>
  )
}

export default EstimateTreeTable;