import * as dataService from '../../shared/_services';
import { toast } from 'views/App/_actions';
import { history } from 'utils/history';
import { canAccess } from 'utils/helpers';
import { EDU_MODE } from 'utils/config';
import {
  CREATE_SUCCESS,
  UPDATE_SUCCESS,
  REGISTER_SUCCESS,
  SET_ACTIVE_SUCCESS,
  SET_VALUE,
  FAILURE,
  SENDING_REQUEST,
  RESET_FORM,
} from './_constants';
const listPath = '/companies';

export const resetForm = () => (dispatch) => {
  dispatch({ type: RESET_FORM });
}

export const registerNew = (details) => async (dispatch) => {
  dispatch({ type: SENDING_REQUEST });

  try {
    const newAccount = await dataService.registerNew(details);

    dispatch({ type: REGISTER_SUCCESS });

    if (EDU_MODE) {
      toast('Paskyra sukurta ir laukia administratoriaus patvirtinimo', 'success');
      history.push('/');
    } else {
      history.push(`/accounts/${newAccount._id}/switch`);
    }
  } catch (error) {
    dispatch({ type: FAILURE, error });
    try {
      toast(error.response.data.message, 'error');
    } catch (err) {
      toast('Nepavyko išsiųsti registracijos duomenų', 'error');
    }

  }
}

export const getOne = (id) => async (dispatch) => {
  dispatch({ type: SENDING_REQUEST });

  try {
    const record = await dataService.getOne(id);

    dispatch({ type: CREATE_SUCCESS, record });
  } catch (error) {
    dispatch({ type: FAILURE, error });
  }

}

export const updateOne = (details) => async (dispatch) => {
  dispatch({ type: SENDING_REQUEST });

  try {
    await dataService.updateOne(details);

    // Updated successfully
    dispatch({ type: UPDATE_SUCCESS });
    history.push(canAccess('administrator.layout') ? listPath : '/dashboard');
    toast('Atnaujinta', 'success');
  } catch (error) {
    // Update failed
    dispatch({ type: FAILURE });
    toast('Nepavyko atnaujinti', 'error');
  }
}

export const createOne = (details) => async (dispatch) => {
  dispatch({ type: SENDING_REQUEST });

  try {
    await dataService.createOne(details);

    // company created successfully
    dispatch({ type: CREATE_SUCCESS });
    history.push(listPath);
    toast('Įrašas sukurtas', 'success');
  } catch (error) {
    // company create failed
    dispatch({ type: FAILURE });
    try {
      toast(error.response.data.message, 'error');
    } catch (err) {
      toast('Nepavyko sukurti įrašo', 'error');
    }
  }
}

export const setActive = (id, isActive) => async (dispatch) => {
  dispatch({ type: SENDING_REQUEST });

  try {
    await dataService.setActive(id, isActive);

    dispatch({ type: SET_ACTIVE_SUCCESS });
    history.push(listPath);
    toast(`Įmonė ${isActive ? 'aktyvuota' : 'deaktyvuota'}`, 'success');
  } catch (error) {
    // company create failed
    dispatch({ type: FAILURE });
    toast('Nepavyko pakeisti įmonės aktyvacijos būsenos', 'error');
  }
}

export const setValue = (data) => (dispatch) => {
  dispatch({ type: SET_VALUE, data });
}
