import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { withStyles, Typography, Chip } from '@material-ui/core';
import { observer } from 'mobx-react';

import { DataTable, CommonPanel } from 'components';
import Orders from 'views/Orders';
import { timestampToDate, twoDecimals, canAccess } from 'utils/helpers';

import styles from './styles';

class Container extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAdmin: false,
      searchText: null,
      isLoading: true,
      selectedColumn: null
    };

    this.onSearchChange = _.debounce(this.onSearchChange, 500);
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    const isAdmin = canAccess('administrator.layout');
    this.setState({ isAdmin });

    this.props.store.getAll();
    this.setState({ isLoading: false });
  }

  onSearchChange(page, pageSize, sortColumn, sortOrder, search, filters) {
    if (search?.length > 0) {
      this.setState({
        searchText: search
      });
    } else this.setState({ searchText: '' });
    this.handleTableChange(
      page,
      pageSize,
      sortColumn,
      sortOrder,
      search,
      filters
    );
  }

  handleTableChange = async (
    page,
    pageSize,
    sortColumn,
    sortOrder,
    search,
    filters
  ) => {
    this.setState({ isLoading: true });
    const { projectId } = this.props;
    const filter = { page, pageSize, sortColumn, sortOrder, search, filters };
    if (projectId) filter.project = projectId;

    const params = {};
    if (page) params.page = page;
    if (pageSize) params.pageSize = pageSize;
    if (sortColumn) params.sortColumn = sortColumn;
    if (sortOrder) params.sortOrder = sortOrder;
    if (search) params.search = search;
    if (filters) params.filters = filters;

    await this.props.store.getAll(params);
    this.setState({ isLoading: false });
  };

  componentWillUnmount() {
    this.props.store.reset();
  }

  prepareTableColumns() {
    const { t } = this.props;
    const { isAdmin } = this.state;

    const columns = [
      'ID',
      'LINK',
      { 
        name: t('Užsakymas'), 
        value: '_id', 
        options: { 
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <b><a href={value.value}>{value.number}</a></b>
            );
          }
        } 
      },
      {
        name: t('Būsena'),
        value: 'status',
        options: {
          filter: true,
          customBodyRender: this.renderStatusHtml.bind(this),
          sort: true
        }
      },
      { name: t('Įmonė'), value: 'company.name', options: { filter: false } },
      {
        name: t('Įmonės adm.'),
        value: 'company.owner.user.fullName',
        options: { filter: false }
      },
      {
        name: t('Sukūrė'),
        value: 'owner.user.email',
        options: { filter: false }
      },
      {
        name: t('Licencijos'),
        value: 'totalLicences',
        options: { sort: false, filter: false }
      },
      { name: t('Terminas'), value: 'months', options: { filter: false } },
      { name: t('Įvestas'), value: 'createdAt', options: { filter: false } },
      {
        name: t('Lic. galioja nuo'),
        value: 'date',
        options: { filter: false }
      },
      {
        name: t('Lic. galioja iki'),
        value: 'expiresAt',
        options: { filter: false }
      },
      {
        name: t('Suma, EUR'),
        value: 'totals.grandTotal',
        options: { filter: false }
      },
      {
        name: t('S/F'),
        value: 'invoiceNumber.fullString',
        options: { filter: false }
      },
      { name: t('Pastabos'), options: { sort: false, filter: false } },
      { name: t('Terminas'), value: 'months', options: { filter: false } },
      { name: t('Įvestas'), value: 'createdAt', options: { filter: false } },
      {
        name: t('Lic. galioja nuo'),
        value: 'date',
        options: { filter: false }
      },
      {
        name: t('Lic. galioja iki'),
        value: 'expiresAt',
        options: { filter: false }
      },
      {
        name: t('Suma, EUR'),
        value: 'totals.grandTotal',
        options: { filter: false }
      },
      {
        name: t('S/F'),
        value: 'invoiceNumber.fullString',
        options: { filter: false }
      },
      { name: t('Pastabos'), options: { sort: false, filter: false } },
      isAdmin
        ? {
            name: t('Adm. komentaras'),
            options: { sort: false, filter: false }
          }
        : ''
    ];

    return columns;
  }

  prepareTableData(records) {
    const { t } = this.props;
    const { isAdmin } = this.state;
    const data = _.map(records, record => {
      let row = [];

      const showDates =
        record.status !== 'draft' && record.status !== 'pending';

      row.push(record._id);
      row.push(`/orders/${record._id}`);
      row.push({number: record.number, value: `/orders/${record._id}`});
      row.push(this.renderStatus(record));
      row.push(record.company ? record.company.name : '-');
      row.push(this.renderCompanyOwner(record));
      row.push(this.renderOwner(record));
      row.push(this.renderLicenceCount(record));
      row.push(
        !record.expiresAtOverride && !record.expiresAtOverrideDays
          ? `${record.months} ${t('mėn.')}`
          : '-'
      );
      row.push(timestampToDate(record.createdAt));
      row.push(showDates ? timestampToDate(record.date) : null);
      row.push(showDates ? timestampToDate(record.expiresAt) : null);
      row.push(twoDecimals(record.totals.grandTotal));
      row.push(!!record.invoiceNumber?.series ? `${record.invoiceNumber.series}_${record.invoiceNumber.no}` : '-');
      row.push(
        record.comment && (
          <span title={record.comment}>
            {_.truncate(record.comment, { length: 10 })}
          </span>
        )
      );
      if (isAdmin) {
        row.push(
          record.internalComment && (
            <span title={record.internalComment}>
              {_.truncate(record.internalComment, { length: 20 })}
            </span>
          )
        );
      }
      return row;
    });

    return data;
  }

  renderCompanyOwner(record) {
    return (
      <span title={_.get(record, 'company.owner.user.email', '-')}>
        {_.get(record, 'company.owner.user.fullName', '-')}
      </span>
    );
  }

  renderOwner(record) {
    return (
      <span title={_.get(record, 'owner.user.email', '-')}>
        {_.get(record, 'owner.user.fullName', '-')}
      </span>
    );
  }

  renderLicenceCount({ totalLicences, licences }) {
    const { t } = this.props;
    const usedLicences = licences.length;

    return (
      <span
        title={`${t('Naudojama')} ${usedLicences} ${t(
          'lic. iš'
        )} ${totalLicences}`}>
        {`${usedLicences} / ${totalLicences}`}
      </span>
    );
  }

  renderStatusHtml(value) {
    const { t } = this.props;
    let status = value;
    let color = 'black';

    switch (value) {
      case t('Laukiama administratoriaus patvirtinimo'):
        color = 'blue';
        break;

      case t('Nebegalioja'):
        color = 'red';
        break;

      case t('Aktyvus'):
        color = 'green';
        break;

      case t('Aktyvus (A)'):
        color = 'purple';
        break;

      case t('Nepateiktas'):
        color = 'black';
        break;

      case t('Bandomoji'):
        color = 'orange';
        break;

      default:
        status = value || '-';
        break;
    }

    return <span style={{ fontWeight: 'bold', color }}>{status}</span>;
  }

  renderStatus({ status: value, trialServiceCode, paymentDelayed }) {
    const { t } = this.props;
    let status = value;

    if (trialServiceCode && status === 'paid') status = 'demo';

    switch (status) {
      case 'pending':
        status = t('Laukiama administratoriaus patvirtinimo');
        break;

      case 'expired':
        status = t('Nebegalioja');
        break;

      case 'paid':
        if (paymentDelayed) {
          status = t('Aktyvus (A)');
        } else status = t('Aktyvus');
        break;

      case 'draft':
        status = t('Nepateiktas');
        break;

      case 'demo':
        status = t('Bandomoji');
        break;

      default:
        status = value || '-';
        break;
    }

    return status;
  }

  renderSummaryOrderNumber({ orderNumber, isTrial }) {
    const { t } = this.props;
    if (!isTrial) return orderNumber;

    return (
      <>
        {orderNumber}
        <div style={{ marginTop: 5 }}>
          <Chip
            variant="outlined"
            color="primary"
            size="small"
            label={t('Bandomoji versija')}
            style={{ fontSize: '11px' }}
          />
        </div>
      </>
    );
  }

  renderSummary() {
    const {
      t,
      store: { summary }
    } = this.props;
    const { isAdmin } = this.state;

    if (isAdmin) {
      return (
        <Typography variant="h6" style={{ textAlign: 'center', padding: 10 }}>
          {t('Aktyvios licencijos rodomos tik įmonės administratoriui')}
        </Typography>
      );
    }

    if (!summary.length) {
      return (
        <Typography variant="h6" style={{ textAlign: 'center', padding: 10 }}>
          {t('Nėra aktyvių licencijų')}
        </Typography>
      );
    }

    const columns = [
      'ID',
      'LINK',
      t('Paslauga'),
      t('Paketas'),
      t('Funkcionalumas'),
      t('Užsakymas'),
      t('Terminas'),
      t('Galioja iki'),
      t('Viso lic.'),
      t('Priskirtos lic.'),
      t('Nepriskirtos lic.')
    ];

    let data = [];

    _.forEach(summary, service => {
      _.forEach(service.packages, servicePackage => {
        _.forEach(servicePackage.items, item => {
          let row = [];

          const freeLicences =
            _.toNumber(item.totalLicences) - _.toNumber(item.usedLicences);

          row.push(item._id);
          row.push(`/orders/${item.orderId}`);
          row.push(service.title);
          row.push(servicePackage.title);
          row.push(item.details.join('. '));
          row.push(this.renderSummaryOrderNumber(item));
          row.push(`${item.months} mėn.`);
          row.push(timestampToDate(item.expiresAt));
          row.push(item.totalLicences);
          row.push(item.usedLicences);
          row.push(freeLicences);

          data.push(row);
        });
      });
    });

    return (
      <DataTable
        key={data.length}
        columns={columns}
        data={data}
        rootStyle={{ border: '0px' }}
      />
    );
  }

  render() {
    const { classes, store, t } = this.props;
    const { isLoading } = this.state;

    const columns = this.prepareTableColumns();
    const data = this.prepareTableData(store.records);
    const title = t('Paslaugų užsakymai');

    const tableOptions = {
      serverSide: true,
      count: store.totalRecords || 0,
      searchText: this.state.searchText,
      pagination: isLoading ? false : true,
      textLabels: {
        body: {
          noMatch: isLoading ? t('Įkeliama') : t('Įrašų nerasta')
        }
      },
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'changeRowsPerPage':
          case 'changePage':
          case 'search':
            this.onSearchChange(
              tableState.page,
              tableState.rowsPerPage,
              tableState.sortColumn,
              tableState.sortOrder,
              tableState.searchText,
              tableState.filters
            );
            break;
          case 'sort':
            const sortColumn = tableState.activeColumn;
            let order = tableState.sortOrder ? tableState.sortOrder : -1;

            if (this.state.selectedColumn === sortColumn) {
              order = tableState.sortOrder * -1;
              tableState.sortOrder = order;
            }

            this.setState({ selectedColumn: sortColumn });
            tableState.sortOrder = order;
            tableState.sortColumn = columns[sortColumn].value;
            this.handleTableChange(
              tableState.page,
              tableState.rowsPerPage,
              tableState.sortColumn,
              tableState.sortOrder,
              tableState.searchText,
              tableState.filters
            );
            break;
          case 'filterChange':
            const matches = [];
            _.map(tableState.filterList, (filterArray, index) => {
              if (filterArray.length > 0) {
                let matchCond = {};
                let col = columns[index].value;

                if (col === 'status') {
                  let value = filterArray[0];
                  switch (value) {
                    case t('Laukiama administratoriaus patvirtinimo'):
                      value = 'pending';
                      break;

                    case t('Nebegalioja'):
                      value = 'expired';
                      break;

                    case t('Aktyvus'):
                      value = 'paid';
                      break;

                    case t('Aktyvus (A)'):
                      value = 'paid';
                      break;

                    case t('Nepateiktas'):
                      value = 'draft';
                      break;

                    case t('Bandomoji'):
                      value = 'demo';
                      break;

                    default:
                      value = {};
                      break;
                  }
                  matchCond[col] = value;
                } else {
                  matchCond[col] = filterArray[0];
                }
                matches.push({ matchCond });
              }
            });
            if (matches.length < 1) {
              tableState.filters = undefined;
            } else {
              matches.push(tableState.filters);
              tableState.filters = matches[0].matchCond;
            }
            this.handleTableChange(
              tableState.page,
              tableState.rowsPerPage,
              tableState.sortColumn,
              tableState.sortOrder,
              tableState.searchText,
              JSON.stringify(tableState.filters)
            );

            break;
          case 'resetFilters':
            tableState.filters = undefined;
            this.handleTableChange(
              tableState.page,
              tableState.rowsPerPage,
              tableState.sortColumn,
              tableState.sortOrder,
              tableState.searchText,
              JSON.stringify(tableState.filters)
            );
          default:
            // Not handling other actions
            break;
        }
      }
    };

    return (
      <Orders
        title={title}
        breadcrumbs={[{ title: t('Administravimas') }, { title: title }]}>
        <div className={classes.wrap}>
          <DataTable
            columns={columns}
            data={isLoading ? [] : [...data] }
            tableHeight={window.innerHeight > 900 ? '700px' : '490px'}
            createUrl="/orders/create"
            createButtonText={t('Pateikti užsakymą')}
            options={tableOptions}
          />
        </div>

        <div className={classes.wrap} style={{ marginTop: 20 }}>
          <CommonPanel title={t('Aktyvios licencijos')} noPadding>
            {this.renderSummary()}
          </CommonPanel>
        </div>
      </Orders>
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation()
)(observer(Container));
