import React, { createRef, useState, useEffect } from 'react';
import readXlsxFile from 'read-excel-file';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { TreeTable } from 'components/TreeTable';
import { genColumns as excelColumnNamesGenerator } from 'excel-like-column-name-generator';
// import parse from 'paste-from-excel';
import _ from 'lodash';
import {
  makeStyles,
  // TableContainer,
  // Table,
  // TableRow,
  // TableCell,
  // TableHead,
  Grid,
  Button,
} from '@material-ui/core';
import {
  CommonDialog,
  FormField2 as Field,
} from 'components';
import api from 'utils/api';

const useStyles = makeStyles({
  root: {},
  footerWrap: {
    marginTop: 40,
  },
  viewWrap: {
    // height: '600px',
    // overflow: 'auto',
  }
})

const AUTO_MAP = {
  sam: 'estimate',
  sd: 'part',
  sk: 'chapter',
  psk: 'subchapter',
  ikn: 'rate',
  ds: 'resource-work',
  med: 'resource-material',
  mech: 'resource-mechanism',
  ir: 'resource-equipment',
};

const VALID_ROW_TYPES = [
  'estimate', 
  'part', 
  'chapter', 
  'subchapter', 
  'rate', 
  'resource', 
  'resource-work', 
  'resource-material', 
  'resource-mechanism', 
  'resource-equipment'
];

const RatesImportModal = ({ version, objectId, itemId, onClose, topLevelType = 'estimate' }) => {
  const classes = useStyles();
  const fileRef = createRef();
  const [file, setFile] = useState();
  const [record, setRecord] = useState({ columnNames: 'first-row' });
  const [headers, setHeaders] = useState();
  const [firstRow, setFirstRow] = useState();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState();
  const [allSelected, setAllSelected] = useState(false);
  const [allLocked, setAllLocked] = useState(false);
  const [getResourcePricesFromDB, setGetResourcePricesFromDB] = useState(false);
  const [fillColumnData, setFillColumnData] = useState(false);
  const { t } = useTranslation();
  // const { projectId, objectId, itemId } = useParams();

  useEffect(() => {
    if (!file) return;

    setHeaders(null);

    readXlsxFile(file).then((excelRows) => {
      if (!excelRows.length) return window.alert(t('Nėra duomenų'));

      const row = excelRows[0].map((value, idx) => ({ value: idx.toString(), label: value }));
      setFirstRow(row);
      setHeaders(row);
      setRows(excelRows);
    });
  }, [file]);

  useEffect(() => {
    const availableRows = hasFirstRow ? _.tail(result) : result;
    const isAllSelected = availableRows?.filter(r => r.selected)?.length === availableRows?.length;
    const isAllLocked = availableRows?.filter(r => r.locked)?.length === availableRows?.length;

    setAllSelected(isAllSelected);
    setAllLocked(isAllLocked);
  }, [result, , rows]);

  const toggleSelectAll = (checked) => {
    setResult(result?.map(item => ({ ...item, selected: checked })));
  };

  const toggleLockAll = (checked) => {
    setResult(result?.map(item => ({ ...item, locked: checked })));
  };

  const handleClose = (hadChanges = false) => {
    onClose(hadChanges);
  };

  const onFileChange = e => {
    const file = e.target.files[0];
    setFile(file);
  };

  const handleRowfieldChange = (rowIdx, field, value) => {
    const newResult = [...result];
    newResult[rowIdx][field] = value;

    setResult(newResult);
  }

  const handleMappingChange = ({ name, value }) => {
    const newRecord = { ...record, [name]: value };

    if (value === '') {
      delete newRecord[name];
    } else {
      if (name === 'columnNames' && value === 'first-row') {
        setHeaders(firstRow);
      } else if (name === 'columnNames' && value === 'none') {
        const row = excelColumnNamesGenerator(firstRow.length).map((value, idx) => ({ value: idx.toString(), label: value }))
        setHeaders(row);
      }
    }

    setRecord(newRecord);

    setResult(rows.map((rowValues, idx) => {
      let type = newRecord.type ? (rowValues[newRecord.type] || 'rate') : 'rate';
      if (name === 'type') {
        // Attempt to auto select values
        type = AUTO_MAP[rowValues[value]?.toLowerCase()] || type;
      } else {
        type = result?.[idx]?.type || type;
      }

      // Set as selected on first load
      const selected = !result ? true : !!result?.[idx]?.selected;

      const item = {
        key: idx,
        selected,
        type,
        ...columnsRaw.reduce((current, column) => {

          return { ...current, [column.key]: rowValues[newRecord[column.key]] }
        }, {}),
      };

      return item;
    }));
  }

  const correctResources = (items) => {
    let payload = items.map(item => {
      if (item.type.includes('resource')) {
        item.resourceType = item.type.split("-")[1];
        item.type = 'resource';
      }
      return item;
    });

    return payload;
  }

  const getExcelVersion = (items) => {
    let version = '';
    items.map(item => {
      if (item.modifiers != null) {
        let modifiers = item.modifiers.split(";");

        modifiers.map(mod => {
          if (mod.toLowerCase().includes('kainos')) {
            let versionCode = mod.match(/\d/g).join("");

            version = versionCode;
            return version;
          } 
        });
      }
    })

    return version;
  }

  const applyModifiers = (items) => {
    let payload = items.map(item => {
      if (item.modifiers != null) {
        let modifiers = item.modifiers.split(";");
        let updatedSettings = [];
        modifiers.map(mod => {
          if (!mod.toLowerCase().includes('kainos')) {
            const coef = mod.split("=");
            if (['k1', 'k2', 'k3', 'k4'].includes(coef[0].toLowerCase())) {
              updatedSettings.push({ code: coef[0].toLowerCase(), value: coef[1] });
            } else {
              // * 10 * 10 instead of * 100 so javascript doesn't return 15 numbers after decimal
              updatedSettings.push({ code: coef[0].toLowerCase(), unit: 'percent', value: coef[1] * 10 * 10 - 100 });
            }
          }
        })

        if (updatedSettings.length) item.updatedSettings = updatedSettings;
      }
      return item;
    });

    return payload;
  }

  
  const emptyTypeRowCheck = (items) => {
    let payload = items.filter(item => item.selected && item.key != 0 &&
      !VALID_ROW_TYPES.includes(item.type)
    );

    return !!payload.length; 
  }

  const handleSubmit = async () => {
    const hasFirstRow = record?.columnNames === 'first-row';
    const basePayload = (hasFirstRow ? _.tail(result) : result).filter(r => r.selected);
    const emptyTypeRows = emptyTypeRowCheck(basePayload);

    if (emptyTypeRows) {
      window.alert(t('Pažymėtos eilutės neturinčios tipo'));
      return;
    }

    const excelVersion = getExcelVersion(basePayload);
    if (excelVersion && version.code != excelVersion) {
      if (!window.confirm(t('Skaičiavimo objekto ir įkeliamų objektų versija nesutampa. Tęsti kėlimą skaičiavimo objekto versija?'))) {
        return;
      }
    }

    let payload = correctResources(basePayload);
    payload = applyModifiers(payload);

    if (!payload.length) {
      window.alert(t('Nepažymėta nei viena eilutė'));
      return;
    }

    setLoading(true);

    try {
      let query = '';
      if (getResourcePricesFromDB) query = query.concat('?importResources=1');
      if (fillColumnData) query = query ? query.concat('&importEmpty=1') : '?importEmpty=1';
      
      await api.post(`/estimates/${objectId}/items/${itemId}/import-rates${query}`, payload);

      handleClose(true);
    } catch (err) {
      console.log('Import failed', err);
      window.alert('Nepavyko įkelti');
      handleClose();
    }

    setLoading(false);
  };

  const handleMassTypeChange = (value) => {
    const newResult = [...result];
    newResult.forEach(r => !r.locked ? r.type = value : null);

    setResult(newResult);
  }

  const renderSelect = ({ name, value, onChange, options, style, disabled }) => (
    <select
      style={style || { width: '100%' }}
      onChange={onChange}
      value={value}
      name={name}
      disabled={disabled}
    >
      <option value="" />
      {options?.map((field) => (
        <option key={field.value} value={field.value}>{field.label}</option>
      ))}
    </select>
  )

  const rowTypes = [
    { key: 'SAM', value: 'estimate', label: t('Sąmata'), allowedFor: ['estimate'] },
    { key: 'SD', value: 'part', label: t('Sąmatos dalis'), allowedFor: ['estimate'] },
    { key: 'SK', value: 'chapter', label: t('Skyrius'), allowedFor: ['estimate', 'part'] },
    { key: 'PSK', value: 'subchapter', label: t('Poskyris'), allowedFor: ['estimate', 'part', 'chapter'] },
    { key: 'IKN', value: 'rate', label: t('Įkainis'), allowedFor: ['estimate', 'part', 'chapter', 'subchapter'] },
    { key: 'DS', value: 'resource-work', resourceType: 'work', label: t('Darbas'), allowedFor: ['rate', 'estimate', 'part', 'chapter', 'subchapter', 'resource-work'] },
    { key: 'MED', value: 'resource-material', resourceType: 'material', label: t('Medžiaga'), allowedFor: ['rate', 'estimate', 'part', 'chapter', 'subchapter'] },
    { key: 'MECH', value: 'resource-mechanism', resourceType: 'mechanism', label: t('Mechanizmas'), allowedFor: ['rate', 'estimate', 'part', 'chapter', 'subchapter'] },
    { key: 'IR', value: 'resource-equipment', resourceType: 'equipment', label: t('Įrenginys'), allowedFor: ['rate', 'estimate', 'part', 'chapter', 'subchapter'] },
  ].filter(item => item.allowedFor.includes(topLevelType));

  const columnsRaw = [
    { key: 'code', label: t('Kodas') },
    { key: 'title', label: t('Pavadinimas'), width: 300 },
    { key: 'unit', label: t('Mat. vnt.'), width: 80 },
    { key: 'norm', label: t('Norma'), width: 80 },
    { key: 'quantity', label: t('Kiekis') },
    { key: 'price', label: t('Kaina') },
    { key: 'amount', label: t('Suma') },
    { key: 'modifiers', label: t('Papildoma informacija'), width: 140 },
    { key: 'workComposition', label: t('Darbų sudėtis') },
  ];

  const columns = [
    {
      dataIndex: 'selected',
      key: 'selected',
      width: 40,
      title: (
        <div style={{ textAlign: 'center' }}>
          <input
            type="checkbox"
            checked={allSelected}
            style={{ verticalAlign: 'middle' }}
            onChange={(e) => toggleSelectAll(e.currentTarget.checked)}
          />
        </div>
      ),
      render: (value, item) => {

        return (
          <div style={{ textAlign: 'center' }}>
            <input
              type="checkbox"
              checked={value}
              style={{ verticalAlign: 'middle' }}
              onChange={(e) => handleRowfieldChange(item.key, 'selected', e.currentTarget.checked)}
            />
          </div>
        )
      }
    },
    ...columnsRaw.map((col) => ({
      title: (
        <>
          {col.label}
          {renderSelect({
            name: col.key,
            value: record?.[col.key],
            onChange: (e) => handleMappingChange({ name: col.key, value: e.target.value }),
            options: headers,
          })}
        </>
      ),
      dataIndex: col.key,
      key: col.key,
      width: col.width || 100,
      render: (text) => <span className="ellipsis" title={text}>{text}</span>
    })),
    {
      title: (
        <>
          {t('Tipas')}
          {renderSelect({
            name: 'type',
            value: record?.type,
            onChange: (e) => handleMappingChange({ name: 'type', value: e.target.value }),
            options: headers || [],
          })}
        </>
      ),
      dataIndex: 'type',
      key: 'type',
      width: 80,
      render: (text, item) => {

        return renderSelect({
          name: 'type',
          value: item?.type,
          onChange: (e) => handleRowfieldChange(item.key, 'type', e.target.value),
          options: rowTypes,
        })
      }
    },
    {
      dataIndex: 'locked',
      key: 'locked',
      width: 55,
      title: (
        <div style={{ textAlign: 'center' }}>
          {`${t('Išlaikyti tipą')} `}
          <input
            type="checkbox"
            checked={allLocked}
            style={{ verticalAlign: 'middle' }}
            onChange={(e) => toggleLockAll(e.currentTarget.checked)}
          />
        </div>
      ),
      render: (value, item) => {

        return (
          <div style={{ textAlign: 'center' }}>
            <input
              type="checkbox"
              checked={value}
              style={{ verticalAlign: 'middle' }}
              onChange={(e) => handleRowfieldChange(item.key, 'locked', e.currentTarget.checked)}
            />
          </div>
        )
      }
    },
  ];

  const hasMappings = !!Object.keys(_.pick(record, ['code', 'title'])).length;
  const hasFirstRow = record?.columnNames === 'first-row';

  const data = hasMappings ? (hasFirstRow ? _.tail(result) : result) : [];

  return (
    <CommonDialog
      visible
      onClose={handleClose}
      maxWidth={!file ? 'sm' : 'xl'}
      style={{ position: 'relative' }}
      title="Įkainių įkėlimas iš Excel"
      loading={loading}
    >
      <div className={classes.viewWrap}>
        <input
          type="file"
          ref={fileRef}
          onChange={onFileChange}
          accept=".xlsx"
          style={{ display: 'none' }}
        />

        {!file && (
          <div style={{ textAlign: 'center' }}>
            <Button color="primary" variant="contained" onClick={() => fileRef.current.click()}>
              {t('Pasirinkti Excel dokumentą')}
            </Button>
          </div>
        )}

        {!!headers && (
          <>
            <div style={{ margin: '0px auto' }}>
              <Grid
                container
                spacing={2}
              >
                <Grid item xs={4}>
                  <Field
                    label={t('Laukų pavadinimai')}
                    type="select"
                    options={[
                      { value: 'first-row', label: t('Pirmoje eilutėje') },
                      { value: 'none', label: t('Nėra (įvardinti raidėmis A...Z)') },
                    ]}
                    name="columnNames"
                    onChange={handleMappingChange}
                    value={record?.columnNames}
                  />
                </Grid>
              </Grid>

              <h4 style={{ marginTop: 10, marginBottom: 0 }}>{t('Excel stulpelių reikšmės')}</h4>

              <div className="rates-import-table">
                <TreeTable
                  onCellChange={() => { }}
                  columns={columns}
                  data={data}
                  locale={{ emptyText: <div style={{ padding: 30 }}>{t('Nėra duomenų')}</div> }}
                  scroll={{ y: 400 }}
                  footer={() => (
                    <div style={{ textAlign: 'right', display: 'inline-flex', justifyContent: 'flex-end', width: '100%' }}>
                      <div style={{ textAlign: 'center', paddingRight: 12 }}>
                        <input
                          type="checkbox"
                          checked={fillColumnData}
                          style={{ verticalAlign: 'middle', marginRight: 6 }}
                          onChange={(e) => setFillColumnData(!fillColumnData)}
                        />
                        {`${t('Trūkstamus duomenis įkelti iš duomenų bazės')} `}
                      </div>

                      <div style={{ textAlign: 'center', paddingRight: 12 }}>
                        <input
                          type="checkbox"
                          checked={getResourcePricesFromDB}
                          style={{ verticalAlign: 'middle', marginRight: 6 }}
                          onChange={(e) => setGetResourcePricesFromDB(!getResourcePricesFromDB)}
                        />
                        {`${t('Įkelti resursų kainas iš duombazės')} `}
                      </div>

                      <div style={{ display: 'inline-block', marginRight: 5, fontWeight: '500' }}>
                        {t('Priskirti tipą visoms eilutėms')}:
                      </div>
                      <div style={{ display: 'inline-block', marginRight: 7 }}>
                        {renderSelect({
                          // name: col.key,
                          value: '',
                          onChange: (e) => handleMassTypeChange(e.target.value),
                          options: rowTypes,
                          disabled: !result,
                        })}
                      </div>
                    </div>
                  )}
                />
              </div>

              <div style={{ textAlign: 'right', marginTop: 20 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={!record?.code || loading}
                >
                  {t('Įkelti')}
                </Button>
              </div>
            </div>
          </>
        )}

      </div>

    </CommonDialog>
  )
}

export default RatesImportModal;