import React from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  withStyles,
  Button,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  Typography,
  IconButton
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Close as DeleteIcon,
  Add as AddIcon,
  Done as DoneIcon
} from '@material-ui/icons';
import { observer } from 'mobx-react';
import { FormField2, CommonPanel, LicenceManager } from 'components';
import Orders from 'views/Orders';
import OrderItemModal from './components/OrderItemModal';
import { AutoComplete } from 'components';
import {
  twoDecimals,
  timestampToDate,
  toast,
  errorToText
} from 'utils/helpers';
import { history } from 'utils/history';

import styles from './styles';

class Container extends React.Component {
  state = {
    showItemModal: false,
    editingItem: null,
    editingItemIdx: null
  };

  componentDidMount() {
    this.attemptToLoadRecord();
  }

  componentWillUnmount() {
    this.props.store.reset();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.store.reset();
      this.attemptToLoadRecord();
    }
  }

  async attemptToLoadRecord() {
    const { match } = this.props;
    const id = _.get(match, 'params.id', null);

    await this.props.store.loadData(id);

    if (!id) this.onAddItem();
  }

  onChange({ name, value }) {
    const { t } = this.props;
    if (name === 'enableReminders') {
      if (!window.confirm(t('Ar tikrai norite pakeisti pranešimų nustatymus?')))
        return;
    }

    this.props.store.setRecordValue(name, value);
  }

  onShowItemModal() {
    this.setState({ showItemModal: true });
  }

  onCloseItemModal() {
    this.setState({ showItemModal: false });
  }

  onItemSave(data) {
    if (_.isNil(this.state.editingItemIdx)) {
      this.props.store.addItem(data);
    } else {
      this.props.store.updateItem(this.state.editingItemIdx, data);
    }

    this.onCloseItemModal();
  }

  onAddItem() {
    this.props.store.resetDiscountCode();

    this.setState({ editingItem: null, editingItemIdx: null });

    this.onShowItemModal();
  }

  onRemoveItem(idx) {
    const { t } = this.props;
    if (!window.confirm(t('Ar tikrai norite pašalinti?'))) {
      return;
    }

    this.props.store.removeItem(idx);
  }

  onEditItem(idx) {
    this.props.store.resetDiscountCode();

    const item = this.props.store.record.items[idx];
    this.setState({ editingItem: item, editingItemIdx: idx });
    this.onShowItemModal();
  }

  save(submit = false) {
    const {
      t,
      store: { record }
    } = this.props;

    if (record.sendInvoice) {
      if (
        !window.confirm(
          `${t('Ar tikrai norite siųsti sąskaitą faktūrą')}?`
        )
      )
        return;
    }

    const total = _.get(record, 'totals.grandTotal');
    if ((!total || _.toNumber(total) < 0) && !record.items.length) {
      window.alert(t('Užsakymo suma negali būti nulinė'));
      return;
    }

    if (this.props.store.isAdmin && !record.company) {
      window.alert(t('Neparinkta įmonė, kuriai priskirti užsakymą'));
      return;
    }

    if (submit) {
      const msg = this.props.store.shouldPreventSubmit();

      if (msg) return window.alert(msg);
    }

    if (record._id) {
      this.props.store.update(submit);
      if (record?.createInvoiceFile) window.location.reload();
    } else {
      this.props.store.create(submit);
    }
  }

  async onDocumentClick(type) {
    await this.props.store.downloadDocument(type);
  }

  async onSendGeneratedInvoice(invoiceEmailSent) {
    const { t } = this.props;
    let order;
    if (invoiceEmailSent) {
      if (!window.confirm(`${t('Sąskaitos faktūros laiškas buvo siųstas. Siųsti pakartotinai')}?`)) return;
    } else {
      if (!window.confirm(`${t('Ar tikrai norite siųsti sąskaitą faktūrą')}?`)) return;
    }

    if (this.props.store.isAdmin) order = await this.props.store.sendGeneratedInvoice();
    if (order.invoiceEmailSent) {
      toast(t('Sąskaitos faktūros laiškas išsiųstas'));
    } else {
      toast(t('Laiško išsiųsti nepavyko'));
    };
  }

  async onRenewalClick() {
    const { t } = this.props;

    try {
      const newOrderId = await this.props.store.renewal();
      toast(t('Suformuotas užsakymas paslaugų pratęsimui'), 'success');
      history.push(`/orders/${newOrderId}`);
    } catch (err) {
      toast(t('Klaida formuojant užsakymo pratęsimą'), 'error');
    }
  }

  validateDiscount = async () => {
    const { t } = this.props;
    const { record } = this.props.store;
    record.totals.discountAmount = record.totals.discountPercent = 0;
    try {
      await this.props.store.recalculate();
      toast(t('Nuolaida pritaikyta'), 'success');
    } catch (err) {
      const msg = _.get(err, 'response.data.message');
      if (msg) {
        toast(t(errorToText(msg)), 'error');
      }
      this.props.store.doRecalc();
    }
  };

  renderFormField({ name, ...field }) {
    const { record } = this.props.store;

    return (
      <FormField2
        {...field}
        name={name}
        // autoComplete={`project-${name}`}
        key={name}
        value={record ? _.get(record, name) : field.multiple ? [] : ''}
        onChange={this.onChange.bind(this)}
      />
    );
  }

  renderCompanyField({ name, ...field }) {
    const { record } = this.props.store;

    return (
      <AutoComplete
        {...field}
        name={name}
        freeSolo={true}
        creatable={false}
        className={this.props.classes.autocomplete}
        value={record.company?.id}
        style={{ height: '32px', marginTop: '8px' }}
        onChange={newValue =>
          this.onChange({
            name: 'company',
            value: newValue !== null ? newValue.value : null
          })
        }
      />
    );
  }

  renderButtons() {
    const { t } = this.props;
    const { record, isAdmin, isReadonly, isValidForSubmit } = this.props.store;
    const isDisabled = !isValidForSubmit;

    return (
      <div style={{ margin: '10px auto', width: 250 }}>
        {record._id && record.isRenewable && !record.renewedBy ? (
          <Button
            color="primary"
            variant="contained"
            fullWidth
            style={{ marginTop: 10, marginBottom: 30 }}
            onClick={() => this.onRenewalClick()}>
            {t('Pratęsti paslaugas')}
          </Button>
        ) : null}

        {record._id && record.status === 'pending' && !record.isRenewable ? (
          <Button
            onClick={() => this.onDocumentClick('prepayment')}
            color="primary"
            variant="contained"
            fullWidth
            style={{ marginTop: 10, marginBottom: 30 }}>
            {t('Išankstinė sąskaita')}
          </Button>
        ) : null}

        {record._id &&
        record.invoiceNumber &&
        !record.disableInvoicePrinting ? (
          <Button
            onClick={() => this.onDocumentClick('invoice')}
            color="primary"
            variant="contained"
            fullWidth
            style={{ marginTop: 10, marginBottom: 30 }}>
            {t('Sąskaita faktūra')}
          </Button>
        ) : null}
        {record._id && isAdmin && record.invoiceNumber ? (
          <Button
            onClick={() => this.onSendGeneratedInvoice(record.invoiceEmailSent)}
            color="primary"
            variant="contained"
            fullWidth
            style={{ marginTop: 10, marginBottom: 10 }}>
            {t('Siųsti sąskaitą faktūrą')}
          </Button>
        ) : null}

        {record._id &&
        isAdmin &&
        !record.invoiceNumber &&
        (record.status === 'paid' || record.status === 'expired') &&
        !record.trialServiceCode ? (
          <div>
            <input
              type="checkbox"
              name="createInvoiceFile"
              onChange={e => {
                this.onChange({ name: e.target.name, value: e.target.checked })
                }
              }
              checked={record.createInvoiceFile || false}
            />
            <Typography style={{ display: 'inline-block', marginLeft: 5 }}>
              {t('Formuoti sąskaitą faktūrą')}
            </Typography>
            <br />
            <input
              type="checkbox"
              name="sendInvoice"
              onChange={e => {
                this.onChange({ name: e.target.name, value: e.target.checked })
                if (!record.createInvoiceFile && e.target.checked) this.onChange({ name: 'createInvoiceFile', value: true })
                }
              }
              checked={record.sendInvoice || false}
            />

            <Typography style={{ display: 'inline-block', marginLeft: 5 }}>
              {t('Siųsti sąskaitą faktūrą')}
            </Typography>
          </div>
        ) : null}

        {(!isAdmin && !isReadonly) || isAdmin ? (
          <Button
            color="primary"
            variant="contained"
            onClick={() => this.save()}
            disabled={isDisabled}
            fullWidth
            style={{ marginTop: 10, marginBottom: 10 }}>
            {t('Išsaugoti duomenis')}
          </Button>
        ) : null}

        {!isReadonly && !isAdmin ? (
          <Button
            color="primary"
            variant="contained"
            onClick={() => this.save(true)}
            disabled={isDisabled}
            fullWidth
            style={{ marginTop: 10, marginBottom: 10 }}>
            {t('Pateikti užsakymą')}
          </Button>
        ) : null}

        {isAdmin && record._id ? (
          <Button
            color="primary"
            variant="contained"
            onClick={() => history.push(`/orders/${record._id}/deploy`)}
            fullWidth
            style={{ backgroundColor: 'orange', marginTop: 10 }}>
            {t('Klonuoti ir išdalinti licencijas')}
          </Button>
        ) : null}
      </div>
    );
  }

  renderInternalCommentField() {
    const { t } = this.props;
    const { isAdmin, record } = this.props.store;

    return isAdmin ? (
      <FormField2
        style={{ backgroundColor: '#ffdddb' }}
        name="internalComment"
        type="text"
        value={record.internalComment}
        label={t('Komentaras (matoma sistemos administratoriui)')}
        onChange={this.onChange.bind(this)}
        multiline
        rows={7}
      />
    ) : null;
  }

  renderClientFields() {
    const { t } = this.props;
    const style = { marginTop: 4 };
    const isCompany =
      _.get(this.props.store.data, 'company.type') !== 'personal';

    return (
      <>
        <Typography variant="body1" style={{ marginBottom: 5 }}>
          <strong>Pirkėjo duomenys:</strong>
        </Typography>
        <Grid container spacing={1}>
          <Grid item md={12}>
            {this.renderFormField({
              name: 'clientName',
              label: `${t('Pirkėjas')}*`,
              type: 'text',
              style,
              shrink: true
            })}
            {this.renderFormField({
              name: 'clientAddress',
              label: `${t('Pirkėjo adresas')}*`,
              type: 'text',
              style,
              shrink: true
            })}
          </Grid>
          <Grid item md={6}>
            {this.renderFormField({
              name: 'clientCode',
              label: `${t('Įmonės kodas')}${isCompany ? '*' : ''}`,
              type: 'text',
              style,
              disabled: isCompany,
              shrink: true
            })}
          </Grid>
          <Grid item md={6}>
            {this.renderFormField({
              name: 'clientVat',
              label: t('PVM mokėtojo kodas'),
              type: 'text',
              style,
              shrink: true
            })}
          </Grid>
          <Grid item md={6}>
            {this.renderFormField({
              name: 'clientEmail',
              label: `${t('El. paštas į kurį siųsti sąskaitą')}*`,
              type: 'email',
              style,
              shrink: true
            })}
          </Grid>
          <Grid item md={6}>
            {this.renderFormField({
              name: 'clientPhone',
              label: t('Kontaktinis tel.'),
              type: 'text',
              style,
              shrink: true
            })}
          </Grid>
          {isCompany && (
            <Grid item md={12}>
              {this.renderFormField({
                name: 'ownerDetails',
                label: t('Užsakymą pateikė'),
                type: 'text',
                style,
                shrink: true,
                disabled: true
              })}
            </Grid>
          )}
          <Grid item md={12}>
            {this.renderFormField({
              name: 'comment',
              label: t('Pastabos'),
              type: 'text',
              multiline: true,
              rows: 3,
              style,
              shrink: true
            })}
          </Grid>
        </Grid>
        <Typography
          variant="body1"
          style={{ marginTop: 5, textAlign: 'right' }}>
          <strong>*</strong> - {t('privalomi nurodyti pateikiant užsakymą')}
        </Typography>
      </>
    );
  }

  renderItemRules(item) {
    const { record } = this.props.store;

    const titles = _.map(item.rules, rule => {
      const itemRule = _.find(record.allRules, { rule });

      return itemRule ? itemRule.title.trim() : null;
    });

    return (
      <ul style={{ listStyle: 'none' }}>
        {titles.length
          ? _.map(titles, (title, idx) => (
              <li key={`details-${title}-${idx}`}>{title}</li>
            ))
          : null}
      </ul>
    );
  }

  renderTable() {
    const { classes, t } = this.props;
    const { record, getServicePackageTitle, isReadonly } = this.props.store;
    const hasItems = record.items && record.items.length;

    const rows = _.map(record.items, (item, idx) => {
      return (
        <TableRow key={`item-${idx}`}>
          <TableCell>{idx + 1}</TableCell>
          <TableCell>{item.serviceTitle}</TableCell>
          <TableCell>{getServicePackageTitle(item.servicePackage)}</TableCell>
          <TableCell>{this.renderItemRules(item)}</TableCell>
          <TableCell align="center">{item.licences}</TableCell>
          {!this.props.store.isAdmin ? 
            (
              <TableCell align="right">{twoDecimals(item.price)}</TableCell>
            ) 
            : 
            (
                <TableCell
                  align="right"
                  style={{ background: '#DFE3E8', paddingRight: 0 }}>
                  {this.renderFormField({
                    name: `items[${idx}].price`,
                    type: 'number',
                    naked: true,
                    value: twoDecimals(item.price),
                    // min: 0,
                    className: classes.inputRight
                  })}
                </TableCell>
            )
          }
          <TableCell className="actions">
            {!isReadonly ? (
              <>
                <IconButton
                  onClick={() => this.onEditItem(idx)}
                  color="primary"
                  title={t('Redaguoti')}>
                  <EditIcon />
                </IconButton>

                <IconButton
                  onClick={() => this.onRemoveItem(idx)}
                  color="primary"
                  style={{ color: 'red' }}
                  title={t('Pašalinti')}>
                  <DeleteIcon />
                </IconButton>
              </>
            ) : null}
          </TableCell>
        </TableRow>
      );
    });

    return (
      <TableContainer>
        <Table
          className={classes.itemsTable}
          // size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '5%' }}>#</TableCell>
              <TableCell style={{ width: '30%' }}>{t('Paslauga')}</TableCell>
              <TableCell>{t('Paketas')}</TableCell>
              <TableCell style={{ width: '30%' }}>
                {t('Funkcionalumas')}
              </TableCell>
              <TableCell style={{ width: '10%' }}>
                {t('Darbo vietų (licencijų)')}
              </TableCell>
              <TableCell style={{ width: '10%' }}>{t('Kaina')}, EUR</TableCell>
              <TableCell className="actions" />
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.length ? (
              rows
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  {t('Nėra pasirinktų paslaugų')}
                </TableCell>
                <TableCell className="actions" />
              </TableRow>
            )}

            {!this.props.store.isReadonly ? (
              <TableRow>
                <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={this.onAddItem.bind(this)}
                    // size="small"
                  >
                    <AddIcon style={{ marginRight: 10 }} />
                    {t('Pridėti paslaugą į užsakymą')}
                  </Button>
                </TableCell>
                <TableCell className="actions" />
              </TableRow>
            ) : null}
          </TableBody>

          {hasItems && record.totals ? (
            <TableFooter>
              {record.status == 'draft' || this.props.store.isAdmin ? (
                <TableRow>
                  <TableCell colSpan={5} align="right">
                    {t('Nuolaidos kodas')}
                  </TableCell>
                  <TableCell
                    style={{
                      background:
                        (!this.props.store.isAdmin &&
                          record.totals.discountAmount > 0) ||
                        (!this.props.store.isAdmin &&
                          record.totals.discountPercent > 0)
                          ? '#DFE3E8'
                          : '#f5fbff',
                      textAlignLast: 'center'
                    }}>
                    {this.renderFormField({
                      name: 'discountCode',
                      type: 'string',
                      naked: true,
                      disabled:
                        (!this.props.store.isAdmin &&
                          record.totals.discountAmount > 0) ||
                        (!this.props.store.isAdmin &&
                          record.totals.discountPercent > 0)
                    })}
                  </TableCell>
                  <TableCell className="actions">
                    <IconButton
                      onClick={() => this.validateDiscount()}
                      color="primary"
                      style={{
                        visibility:
                          (!this.props.store.isAdmin &&
                            record.totals.discountAmount > 0) ||
                          (!this.props.store.isAdmin &&
                            record.totals.discountPercent > 0)
                            ? 'hidden'
                            : 'visible'
                      }}>
                      <DoneIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ) : null}

              {this.props.store.isAdmin ? (
                <>
                  <TableRow>
                    <TableCell colSpan={5} align="right">
                      {t('Nuolaida')}, %
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ background: '#DFE3E8', paddingRight: 0 }}>
                      {this.renderFormField({
                        name: 'totals.discountPercent',
                        type: 'number',
                        naked: true,
                        min: 0,
                        className: classes.inputRight
                      })}
                    </TableCell>
                    <TableCell className="actions" />
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={5} align="right">
                      {t('Nuolaida')}, EUR
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ background: '#DFE3E8', paddingRight: 0 }}>
                      {this.renderFormField({
                        name: 'totals.discountAmount',
                        type: 'number',
                        naked: true,
                        min: 0,
                        className: classes.inputRight
                      })}
                    </TableCell>
                    <TableCell className="actions" />
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={5} align="right">
                      {t('Nuolaidos suma')}, EUR
                    </TableCell>
                    <TableCell align="right">
                      {twoDecimals(record.totals.totalDiscountAmount)}
                    </TableCell>
                    <TableCell className="actions" />
                  </TableRow>
                </>
              ) : null}

              {!this.props.store.isAdmin &&
              _.get(record, 'totals.discountPercent', 0) > 0 ? (
                <TableRow>
                  <TableCell colSpan={5} align="right">
                    {t('Nuolaida')}, %
                  </TableCell>
                  <TableCell align="right">
                    {twoDecimals(record.totals.discountPercent)}
                  </TableCell>
                  <TableCell className="actions" />
                </TableRow>
              ) : null}

              {!this.props.store.isAdmin &&
              _.get(record, 'totals.discountAmount', 0) > 0 ? (
                <TableRow>
                  <TableCell colSpan={5} align="right">
                    {t('Nuolaida')}, EUR
                  </TableCell>
                  <TableCell align="right">
                    {twoDecimals(record.totals.discountAmount)}
                  </TableCell>
                  <TableCell className="actions" />
                </TableRow>
              ) : null}

              {!this.props.store.isAdmin &&
              _.get(record, 'totals.totalDiscountAmount', 0) > 0 ? (
                <TableRow>
                  <TableCell colSpan={5} align="right">
                    {t('Nuolaidos suma')}, EUR
                  </TableCell>
                  <TableCell align="right">
                    {twoDecimals(record.totals.totalDiscountAmount)}
                  </TableCell>
                  <TableCell className="actions" />
                </TableRow>
              ) : null}

              {!this.props.store.isAdmin &&
              twoDecimals(record.totals.total) !==
                twoDecimals(record.totals.correctedTotal) ? (
                <TableRow>
                  <TableCell colSpan={5} align="right">
                    {t('Suma')}, EUR
                  </TableCell>
                  <TableCell align="right">
                    <span
                      style={{
                        textDecoration: 'line-through',
                        marginRight: 5
                      }}>
                      {twoDecimals(record.totals.total)}
                    </span>
                    {twoDecimals(record.totals.correctedTotal)}
                  </TableCell>
                  <TableCell className="actions" />
                </TableRow>
              ) : null}

              {this.props.store.isAdmin ||
              twoDecimals(record.totals.total) ===
                twoDecimals(record.totals.correctedTotal) ? (
                <TableRow>
                  <TableCell colSpan={5} align="right">
                    {t('Suma')}
                    {this.props.store.isAdmin ? ' (apskaičiuota)' : null}, EUR
                  </TableCell>
                  {/* {!this.props.store.isAdmin ? ( */}
                    <TableCell align="right">
                      {twoDecimals(record.totals.total)}
                    </TableCell>
                  {/* ) : (
                    <TableCell
                      align="right"
                      style={{ background: '#DFE3E8', paddingRight: 0 }}>
                      {this.renderFormField({
                        name: 'totals.total',
                        type: 'number',
                        naked: true,
                        // min: 0,
                        className: classes.inputRight
                      })}
                    </TableCell>
                  )} */}
                  <TableCell className="actions" />
                </TableRow>
              ) : null}

              {this.props.store.isAdmin ? (
                <TableRow>
                  <TableCell colSpan={5} align="right">
                    {t('Suma (galutinė, be PVM)')}, EUR
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ background: '#DFE3E8', paddingRight: 0 }}>
                    {this.renderFormField({
                      name: 'totals.correctedTotal',
                      type: 'number',
                      naked: true,
                      min: 0,
                      className: classes.inputRight
                    })}
                  </TableCell>
                  <TableCell className="actions" />
                </TableRow>
              ) : null}

              <TableRow>
                <TableCell colSpan={5} align="right">
                  {t('PVM suma')}, EUR
                </TableCell>
                <TableCell align="right">
                  {twoDecimals(record.totals.vat)}
                </TableCell>
                <TableCell className="actions" />
              </TableRow>
              <TableRow>
                <TableCell colSpan={5} align="right">
                  <strong>
                    {t('Iš viso')}, EUR {t('su PVM')}
                  </strong>
                </TableCell>
                <TableCell align="right">
                  <strong>{twoDecimals(record.totals.grandTotal)}</strong>
                </TableCell>
                <TableCell className="actions" />
              </TableRow>
            </TableFooter>
          ) : null}
        </Table>
      </TableContainer>
    );
  }

  renderItemModal() {
    if (!this.state.showItemModal) return null;

    return (
      <OrderItemModal
        editingItem={this.state.editingItem}
        months={this.props.store.record.months}
        onSave={this.onItemSave.bind(this)}
        onClose={this.onCloseItemModal.bind(this)}
      />
    );
  }

  renderOrderStatus(value) {
    const { t } = this.props;
    let status = value;
    let color = 'black';

    switch (value) {
      case 'pending':
        status = t('Laukiama administratoriaus patvirtinimo');
        color = 'blue';
        break;

      case 'paid':
        status = t('Aktyvus');
        color = 'green';
        break;

      case 'expired':
        status = t('Nebegalioja');
        color = 'red';
        break;

      case 'draft':
        status = t('Nepateiktas');
        color = 'black';
        break;

      default:
        status = value || '-';
        break;
    }

    return <span style={{ color }}>{status}</span>;
  }

  renderForm() {
    const { store, t } = this.props;
    const { record, data, settings } = store;

    const companyOptions =
      !this.props.store.isAdmin || !data.companies
        ? null
        : _.map(data.companies, company => ({
            value: company._id,
            title: company?.owner?.user
              ? `${company.name} (${company.owner.user.fullName}, ${
                  company.owner.user.email
                })`
              : company.registeredBy
              ? `${company.name} (${company.registeredBy.fullName}, ${
                  company.registeredBy.email
                })`
              : company.name
          }));

    let monthsOptions = _.map(
      _.get(settings, 'services.pricingRange', []),
      item => {
        let label = `${item.months} ${t('mėn.')}`;

        return {
          value: item.months,
          label
        };
      }
    );

    const statusOptions = [
      { label: t('Nepateiktas'), value: 'draft' },
      { label: t('Laukiama administratoriaus patvirtinimo'), value: 'pending' },
      { label: t('Aktyvus'), value: 'paid' },
      { label: t('Nebegalioja'), value: 'expired' }
    ];

    let duration;
    if (record.expiresAtOverrideDays) {
      duration = `${record.expiresAtOverrideDays} ${t('d.')}`;
    } else if (record.expiresAtOverride) {
      duration = `${t('iki')} ${moment(record.expiresAtOverride).format(
        'YYYY-MM-DD'
      )}`;
    } else {
      duration = `${record.months} ${t('mėn.')}`;
    }

    return (
      <>
        <Grid container spacing={1}>
          <Grid item md={5}>
            <Typography variant="body1" style={{ marginBottom: 5 }}>
              <strong>Užsakymo Nr.:</strong> {record.number || '-'}
            </Typography>
            {record.invoiceNumber && (
              <Typography variant="body1" style={{ marginBottom: 5 }}>
                <strong>{t('Sąskaita faktūra')}:</strong>{' '}
                {record.invoiceNumber.series}_{record.invoiceNumber.no}
              </Typography>
            )}
            {!this.props.store.isAdmin ? (
              <Typography variant="body1" style={{ marginBottom: 5 }}>
                <strong>{t('Būsena')}:</strong>{' '}
                {this.renderOrderStatus(record.status)}
              </Typography>
            ) : null}
            <Typography variant="body1" style={{ marginBottom: 5 }}>
              <strong>{t('Užsakymas pateiktas')}:</strong>{' '}
              {record.date ? timestampToDate(record.date) : '-'}
            </Typography>
            <Typography variant="body1" style={{ marginBottom: 5 }}>
              <strong>{t('Užsakytų paslaugų trukmė')}:</strong> {duration}
            </Typography>
            {record.renewalOf ? (
              <Typography variant="body1" style={{ marginBottom: 5 }}>
                <strong>{t('Pratęstas užsakymas')}:</strong>{' '}
                <Link to={`/orders/${record.renewalOf._id}`}>
                  {record.renewalOf.number}
                </Link>
              </Typography>
            ) : null}
          </Grid>
          <Grid item md={5}>
            {record._id && (
              <>
                <Typography variant="body1" style={{ marginBottom: 5 }}>
                  <strong>{t('Pirkėjas')}:</strong> {record.clientName || '-'}
                </Typography>
                <Typography variant="body1" style={{ marginBottom: 5 }}>
                  <strong>{t('Pirkėjo adresas')}:</strong>{' '}
                  {record.clientAddress || '-'}
                </Typography>
                <Typography variant="body1" style={{ marginBottom: 5 }}>
                  <strong>{t('Įmonės kodas')}:</strong>{' '}
                  {record.clientCode || '-'}
                </Typography>
                <Typography variant="body1" style={{ marginBottom: 5 }}>
                  <strong>{t('PVM mokėtojo kodas')}:</strong>{' '}
                  {record.clientVat || '-'}
                </Typography>
                <Typography variant="body1" style={{ marginBottom: 5 }}>
                  <strong>{t('El. paštas sąskaitai')}:</strong>{' '}
                  {record.clientEmail || '-'}
                </Typography>
                <Typography variant="body1" style={{ marginBottom: 5 }}>
                  <strong>{t('Kontaktinis tel.')}:</strong>{' '}
                  {record.clientPhone || '-'}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>

        {record._id && record.status !== 'expired' && (
          <Grid container spacing={1}>
            <Grid item>
              <div>
                <input
                  type="checkbox"
                  name="enableReminders"
                  onChange={e =>
                    this.onChange({
                      name: e.target.name,
                      value: e.target.checked
                    })
                  }
                  checked={record.enableReminders}
                />

                <Typography style={{ display: 'inline-block', marginLeft: 5 }}>
                  {t('Informuoti el. paštu apie užsakymo galiojimo pabaigą')}
                </Typography>
              </div>
            </Grid>
          </Grid>
        )}

        {record._id && this.props.store.isAdmin && record.status === 'paid' && (
          <Grid container spacing={1}>
            <Grid item>
              <div>
                <input
                  type="checkbox"
                  name="paymentDelayed"
                  onChange={e =>
                    this.onChange({
                      name: e.target.name,
                      value: e.target.checked
                    })
                  }
                  checked={record.paymentDelayed}
                />
                <Typography style={{ display: 'inline-block', marginLeft: 5 }}>
                  {t('Mokėjimas atidėtas')}
                </Typography>
              </div>
            </Grid>
          </Grid>
        )}

        <Grid container spacing={1}>
          {this.props.store.isAdmin && !this.props.store.isReadonly && (
            <Grid item md={3}>
              {this.renderCompanyField({
                name: 'company',
                label: t('Įmonė'),
                options: companyOptions
              })}
            </Grid>
          )}

          {!this.props.store.isReadonly && (
            <Grid item md={3}>
              {this.renderFormField({
                name: 'months',
                label: t('Užsakymo galiojimo terminas'),
                type: 'select',
                options: monthsOptions
              })}
            </Grid>
          )}

          {this.props.store.isAdmin && !this.props.store.isReadonly && (
            <>
              <Grid item md={3}>
                {this.renderFormField({
                  name: 'expiresAtOverride',
                  label: t('Užsakymas galioja iki (datos)'),
                  type: 'date'
                })}
              </Grid>
              <Grid item md={3}>
                {this.renderFormField({
                  name: 'expiresAtOverrideDays',
                  label: t('Užsakymas galioja (dienų)'),
                  type: 'number',
                  min: 0,
                  max: 30,
                  style: { marginTop: 8 },
                  shrink: true
                })}
              </Grid>
            </>
          )}

          {this.props.store.isAdmin && record._id ? (
            <Grid item md={3}>
              {this.renderFormField({
                name: 'status',
                label: t('Užsakymo būsena'),
                type: 'select',
                options: statusOptions
              })}
            </Grid>
          ) : null}

          <Grid item md={12}>
            {this.renderTable()}
          </Grid>

          <Grid item md={6}>
            {!this.props.store.isReadonly ? (
              this.renderClientFields()
            ) : (
              <Typography style={{ marginTop: 10 }}>
                {record.comment}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                {this.renderInternalCommentField()}
              </Grid>
              <Grid item xs={12} md={6}>
                {this.renderButtons()}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {record._id && record.renewedBy ? (
          <div style={{ textAlign: 'center', marginTop: 10 }}>
            <Typography variant="h4" style={{ color: 'green' }}>
              {t('Užsakymas pratęstas')}
            </Typography>

            <Typography style={{ marginTop: 10 }}>
              <Link to={`/orders/${record.renewedBy._id}`}>
                {t('Užsakymo pratęsimas')} {record.renewedBy.number}
              </Link>
            </Typography>
          </div>
        ) : null}
      </>
    );
  }

  render() {
    const { classes, store, t } = this.props;
    const title = store.record._id
      ? `${t('Užsakymas')} ${store.record.number}`
      : t('Naujas užsakymas');
    return (
      <Orders
        title={title}
        breadcrumbs={[
          { title: t('Administravimas') },
          { title: t('Paslaugų užsakymai'), to: '/orders' },
          { title: title }
        ]}>
        <div className={classes.wrap}>
          <CommonPanel>
            {this.renderForm()}

            {this.renderItemModal()}
          </CommonPanel>
        </div>

        {/* <div className={classes.wrap}>
          {_.get(store, 'record.company.accounts') ? (
            <CommonPanel
              rootStyle={{ marginTop: 20 }}
              title={t('Užsakymo licencijos')}
            >
              {_.map(store.record.company.accounts, account =>
                <LicenceManager
                  key={account._id}
                  account={account._id}
                  company={store.record.company._id}
                  order={store.record._id}
                />
              )}
            </CommonPanel>
          ) : null}
        </div> */}
      </Orders>
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation()
)(observer(Container));
